import Head from "next/head";
import Link from "next/link";
import { Navbar, NavbarList } from "~/components/navbar";
import threadIcon from "~/assets/thread.svg";

export default function NotFoundPage({ statusCode, errorMessage }) {
  let title = errorMessage ? errorMessage : "Sorry, this page doesn't exist.";
  console.log(statusCode, errorMessage);
  return (
    <>
      <Head>
        <title>Page Not Found – Threads</title>
      </Head>
      <div className="min-h-screen w-full flex flex-col items-center">
        <Navbar className="max-w-7xl py-6 px-4 md:px-6">
          <NavbarList>
            <Link
              href="/"
              className="flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-300 rounded-sm"
              title="threads"
            >
              <img className="h-6 w-auto inline" src={threadIcon} alt="home" />
              <span className="font-bold text-black text-base tracking-tight">
                threads
              </span>
            </Link>
          </NavbarList>
          <NavbarList>
            <Link
              href="/signup"
              className="font-medium text-sm px-1 no-underline hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-300 rounded-sm"
            >
              Sign up
            </Link>
            <Link
              href="/login"
              className="font-medium text-sm px-1 no-underline hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-300 rounded-sm"
            >
              Login
            </Link>
          </NavbarList>
        </Navbar>
        <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 text-center group">
          <Link
            href="/"
            className="text-black visited:text-black focus-visible:outline-none focus-visible:underline"
          >
            <h1 className="text-2xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-3xl mx-auto md:max-w-5xl">
              {title}
            </h1>
            <p className="mt-1 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:text-xl md:max-w-5xl hover:underline">
              Find your way back home.
            </p>
          </Link>
        </div>
      </div>
    </>
  );
}
